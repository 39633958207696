import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { PatientInvoiceService } from 'src/app/_services/PatientInvoice/patient-invoice.service'
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../confirm/confirm';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dateconfig } from 'src/app/configuration/dateconfig/dateconfig';
@Component({
  selector: 'app-allinvoice',
  templateUrl: './allinvoice.component.html',
  styleUrls: ['./allinvoice.component.css'],
  providers: [Dateconfig]
})
export class AllinvoicesComponent implements OnInit {
    public breadcrumb: any;
  options = {
    close: false,
    expand: true,
    minimize: true,
    reload: false
  };
  columns: any = [];
  rows: any;
 
  addModal = null;
  value: any;
  temp = [];
  temp2 = this.rows;
  confirmResult = null;
  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  
  constructor(public patientInvoiceService: PatientInvoiceService,
    private toastrService: ToastrService,
    public SimpleModalService: SimpleModalService,
    private spinner: NgxSpinnerService,
    private dateconfig: Dateconfig,
  ) { }


  ngOnInit() {
    this.spinner.show();
    this.breadcrumb = {
      'mainlabel': 'All Invoices',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/newdashboard'
        },

      ]
    };

    this.patientInvoiceService.GetAllPatientInvoice().subscribe(Response => {
      this.rows = Response;
      this.temp = Response;
      this.temp2 = Response;
     
      this.spinner.hide();


    });


  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.rows = [...this.temp2]; // and here you have to initialize it with your data
    this.temp = [...this.rows];
    // filter our data
    
    const temp = this.rows.filter(function (d) {
      return d.patientFullName.toLowerCase().indexOf(val) !== -1 ||
      d.patientInvoiceBalanceAmountAmount.toString().toLowerCase().indexOf(val) !== -1 ||
      d.patientInvoiceTotalAmount.toString().toLowerCase().indexOf(val) !== -1 ||
      d.patientFirstName.toLowerCase().indexOf(val) !== -1 ||
      d.idPatientInvoice.toString().toLowerCase().indexOf(val) !== -1 ||
      d.idPatient.toString().toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
  }

  showConfirm(id) {
    this.SimpleModalService.addModal(ConfirmComponent, {
      title: 'Confirmation',
      message: 'Are you sure to delete this record ?'
    })
      .subscribe((isConfirmed) => {
        // Get modal result
        this.confirmResult = isConfirmed;

        if (isConfirmed) {
          this.spinner.show();


          this.patientInvoiceService.DeleteById(id).subscribe(
            (res: any) => {

              if (res.result == 1) {
                this.spinner.hide();
                this.toastrService.success(res.userMessage, '', { timeOut: 1000, closeButton: true });
                this.ngOnInit();
              }
              else {
                this.spinner.hide();
                this.toastrService.error(res.userMessage, '', { timeOut: 3000, closeButton: true });

              }

            },
            err => {
              this.spinner.hide();

              this.toastrService.error('server is down', '', { timeOut: 3000, closeButton: true });

            }
          )
        }
      });
  }

  encrypt(id: string) {
    return btoa(id);
  }

  public convertdate(date) {
    return this.dateconfig.coverttoshort(date);
  }
  public convertommyyy(date:any){
    return this.dateconfig.convertommyyy(date);
   }
  
}
