// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  rolename: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      {
        title: 'Changelog',
        icon: 'la-file',
        page: '/changelog',
        badge: { type: 'badge-danger', value: '1.1' }
      },
      {
        title: 'Templates',
        icon: 'la-television',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Horizontal',
              page: 'null'
            },
            {
              title: 'Vertical',
              page: 'null'
            },
          ]
        }
      },
      {
        title: 'Raise Support',
        icon: 'la-support',
        page: 'https://pixinvent.ticksy.com/',
        isExternalLink: true
      },
      {
        title: 'Documentaion',
        icon: 'la-text-height',
        page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
        isExternalLink: true,
      }
    ]
  },

  vertical_menu: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard/newdashboard',
        rolename: 'Admin,Doctor,Receptiniest,Pharmacist'
      },
      // {
      //   title: 'Calendar',
      //   icon: 'la-calendar',
      //   page: '/dashboard',

      // },


      {
        title: 'Users',
        icon: 'la-user',
        page: 'null',
        rolename: 'Admin',
        submenu: {
          items: [
            {
              title: 'Add User',
              page: '/employee/addemployee',
              rolename: 'Admin'

            },

            {
              title: 'All Users',
              page: '/employee/employees',
              rolename: 'Admin'
            }
          ]
        }
      },
      {
          title: 'My Event',
           icon: 'feather ft-star',
          page: '/calendar/addevent',
          rolename: 'Admin,Doctor'
      },
      // {
      //   title: 'Event',
      //   icon: 'feather ft-star',
      //   page: '/calendar/addevent',
      //   rolename: 'Admin,Doctor'
        // submenu: {
        //   items: [
        //     {
        //       title: 'Add Event',
        //       page: '/event/addevent',
        //     },

        //     {
        //       title: 'All Events',
        //       page: '/event/events'
        //     }
        //   ]
        // }
     // },
      {
        title: 'Patient',
        icon: 'la-wheelchair',
        page: 'null',
        rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
        submenu: {
          items: [
            {
              title: 'Add Patient',
              page: '/patient/addpatient',
              rolename: 'Admin,Doctor,Receptiniest,Pharmacist'
            },
            {
              title: 'All Patients',
              page: '/patient/patients',
              rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
            },

            {
              title: 'View Patient Details',
              icon: 'la-wheelchair',
              page: '/viewpatient/viewpatientdetails',
              rolename: 'Admin,Doctor,Receptiniest,Pharmacist'
            },
            // {
            //   title: 'Upload Report',
            //   page: '/patient/addreport',
            //   rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
            // },
            // {
            //   title: 'All Report',
            //   page: '/patient/allreport',
            //   rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
            // }

          ]
        }
      },
      {
        title: 'Patient Report',
        icon: 'la-clipboard',
        page: 'null',
        rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
        submenu: {
          items: [
            {
              title: 'Upload Report',
              page: '/patient/addreport',
              rolename: 'Admin,Doctor,Receptiniest',
            },
            {
              title: 'All Report',
              page: '/patient/allreport',
              rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
            }

          ]
        }
      },
      {
        title: 'Visit',
        icon: 'la-map-marker',
        page: 'null',
        rolename: 'Admin',
        submenu: {
          items: [
            {
              title: 'Add Visit',
              page: '/patientvisit/addPatientvisit',
              rolename: 'Admin,Doctor,Receptiniest',
            }
          ]
        }
      },
      {
        title: 'Appointment',
        icon: 'la-calendar',
        page: 'null',
        rolename: 'Admin,Receptiniest,',
        submenu: {
          items: [
            {
              title: 'Add Appointment',
              page: '/appointment/addAppointments',
              rolename: 'Admin,Receptiniest',
            },
            {
              title: 'All Appointments',
              page: '/appointment/Appointments',
              rolename: 'Admin,Receptiniest',
            }
          ]
        }
      },
      {
        title: 'Treatment',
        // icon: 'la-scissors',
        icon: '  la-medkit',
        page: 'null',
        rolename: 'Admin,Doctor',
        submenu: {
          items: [
            {
              title: 'Add Treatment',
              page: '/patienttreatment/addpatienttreatment',
              rolename: 'Admin,Doctor',
            }
          ]
        }
      },

      {
        title: 'Invoice',
        icon: 'la-check',
        page: 'null',
        rolename: 'Admin,Doctor,Receptiniest',
        submenu: {
          items: [
            {
              title: 'Add Invoice',
              page: '/invoice/addinvoice',
              rolename: 'Admin,Doctor,Receptiniest',
            },
            {
              title: 'All Invoices',
              page: '/invoice/invoices',
              rolename: 'Admin,Doctor,Receptiniest',
            }
          ]
        }
      },
 
      {
        title: 'Pharmacist',
        icon: 'icon-paper-clip',
        page: 'null',
        rolename: 'Admin,Doctor,Pharmacist',
        submenu: {
          items: [
            {
              title: 'New Prescription',
              page: '/prescription/addPrescription',
              rolename: 'Admin,Doctor,Pharmacist',
            },
            {
              title: 'All Prescription',
              page: '/prescription/prescriptions',
              rolename: 'Admin,Doctor,Pharmacist',
            }
          ]
        }
      },


      {
        title: 'Reports',
        icon: 'la-clipboard',
        page: 'null',
        rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
        submenu: {
          items: [
            {
              title: 'Patient Report',
              page: '/patientreport/OnlyPatienDetailReport',
              rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
            },
            {
              title: 'Patient History Report',
              page: '/patientreport/PatienDetailWithVistAndTreatmentReport',
              rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
            }
          ]
        }
      },
      // {
      //   title: 'Profile and Password',
      //   icon: 'feather ft-edit',
      //   page: 'null',
      //   rolename: 'Admin,Doctor,Receptiniest,Pharmacist',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Edit Profile',
      //         page: '/profile/updateprofile',
      //         rolename: 'Admin,Doctor,Receptiniest,Pharmacist'

      //       },

      //       {
      //         title: 'Change Password',
      //         page: '/profile/updatepass',
      //         rolename: 'Admin,Doctor,Receptiniest,Pharmacist'
      //       }
      //     ]
      //   }
      // },





    ]
  }
};





