import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from 'src/app/_services/patient/patient.service';
import { PrescriptionService } from 'src/app/_services/Prescription/prescription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Dateconfig } from 'src/app/configuration/dateconfig/dateconfig';
import { CustomLoaderCComponent } from 'src/app/CustomLoader/custom-loader/custom-loader-c/custom-loader-c.component';
import { ToastrService } from 'ngx-toastr';
import{PatientvisitService } from 'src/app/_services/Patientvisit/patientvisit.service'
import { Prescription } from 'src/app/Entites/Prescription/prescription';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.css'],
  providers: [Dateconfig,CustomLoaderCComponent]

})
export class AddPrescriptionComponent implements OnInit {
  @ViewChild('ng-select') ngSelect;
  @BlockUI('singleSelect') blockUISingleSelect: NgBlockUI;
  @BlockUI('multipleSelect') blockUIMultipleSelect: NgBlockUI;
  @BlockUI() blockUI: NgBlockUI;
  
  constructor(
    private patientService: PatientService,
    private PrescriptionService: PrescriptionService,
    private router: ActivatedRoute,
    private routernav: Router,
    private PatientvisitService: PatientvisitService,
    private dateconfig: Dateconfig,
    private spinner: CustomLoaderCComponent,
    private toastrService: ToastrService,
  ) { }
  public patientArray;
  public prescriptionarray = [];
  Prescription = new Prescription()
  idPatientVisitp: string;
  public breadcrumb:any;
  public booleanPatientArrya = false;
  public booleanVisitArray=false;
  rows: any;
  model: any = {};
  model1: any = {};
  idPatient: number;
  public showpatientdetails: boolean = false;
  public showvisitedetails: boolean = false;
  public VisitArray = [];
  rows1: any;

  ngOnInit() {
      this.patientService.GetAllpatient().subscribe(Response => {
      this.patientArray = Response;
    });
    
    this.Prescription = new Prescription();
    this.prescriptionarray.push(this.Prescription);

    this.idPatientVisitp = this.router.snapshot.queryParamMap.get('idPatientVisitp');
    this.idPatientVisitp = atob(this.idPatientVisitp);
    if (Number(this.idPatientVisitp) > 0) {
      this.breadcrumb = {
        'mainlabel': 'Update Prescription',
        'links': [
          {
            'name': 'Home',
            'isLink': true,
            'link': '/dashboard/newdashboard'
          },

        ]
      };
      this.booleanPatientArrya = true;
      this.booleanVisitArray=true;
      this.PrescriptionService.GetPatientPrescriptionByIdPatientVisit(Number(this.idPatientVisitp)).subscribe(Response => {
    
        this.rows = Response;
        this.prescriptionarray = this.rows.patientPrescriptions;
        this.model1.idPatient = this.rows.idPatient;
        this.model.idPatientVisit = this.rows.idPatientVisit;
       
        this.model1.patientVisitDate=this.dateconfig.coverttoshort(this.rows.patientVisitDate);
        this.model1.patientVisitDescription=this.rows.patientVisitDescription;
        this.GetAllVisitByPatientId(this.rows.idPatient);
        for(var i=0;i<this.patientArray.length;i++){
         
          if(this.patientArray[i].idPatient==this.rows.idPatient)
          {
            this.model1.patientContactNo = this.patientArray[i].patientContactNo;
            this.model1.patientFirstName = this.patientArray[i].patientFirstName;
            this.model1.patientLastName = this.patientArray[i].patientLastName;
            this.model1.patientEmailId = this.patientArray[i].patientEmailId;
            this.model1.patientGender = this.patientArray[i].patientGender;
            this.model.idPatient = this.patientArray[i].idPatient;
            this.model1.dOB = this.dateconfig.coverttoshort(this.patientArray[i].dob);
            this.idPatient = this.patientArray[i].idPatient;
            this.showpatientdetails = true;
            this.model.selectpatient=this.patientArray[i];

          }
        }
      });
      this.showpatientdetails = true;
      this.showvisitedetails = true;

    }
    else {
      this.idPatientVisitp="0";

      this.breadcrumb = {
        'mainlabel': 'Add Prescription',
        'links': [
          {
            'name': 'Home',
            'isLink': true,
            'link': '/dashboard/newdashboard'
          },
        ]
      };

    }
  }

  SavePrescription(isValid: any) {

    if (isValid) {
      this.spinner.showSpinner();
      if (this.prescriptionarray[0].idPatientPrescription == undefined) {
        for (var i = 0; i < this.prescriptionarray.length; i++) {
          if (this.prescriptionarray[i].operationState == "d") {
            this.prescriptionarray.splice(i, 1);
          }
        }
        this.model.patientPrescriptions = this.prescriptionarray;
        this.model.idPatient = this.idPatient;
        this.model.patientVisitModifiedBy=localStorage.getItem('Id');

        this.PrescriptionService.savePatientPrescription(this.model).subscribe(
          (res: any) => {
            if (res.result == 1) {
              this.spinner.hideSpinner();
              this.toastrService.success(res.userMessage, '', { timeOut: 2000, closeButton: true });
              this.routernav.navigate(['/dashboard/newdashboard']);
            }
            else {
              this.spinner.hideSpinner();

              this.toastrService.error(res.userMessage, '', { timeOut: 3000, closeButton: true });
            }
          },
          err => {
            this.spinner.hideSpinner();

            this.toastrService.error('server down', '', { timeOut: 3000, closeButton: true });
          }
        )
      }
      else {
        
        for (var i = 0; i < this.prescriptionarray.length; i++) {
          if (this.prescriptionarray[i].idPatientPrescription == undefined && this.prescriptionarray[i].operationState == "d") {
            this.prescriptionarray.splice(i, 1);
          }
        }
        
        this.model.patientPrescriptions = this.prescriptionarray;
        this.model.idPatient = this.idPatient;
this.model.patientVisitModifiedBy=localStorage.getItem('Id');

        this.PrescriptionService.updatePatientPrescription(this.model).subscribe(
          (res: any) => {
            if (res.result == 1) {
              this.spinner.hideSpinner();

              this.toastrService.success(res.userMessage, '', { timeOut: 2000, closeButton: true });
              this.routernav.navigate(['/dashboard/newdashboard']);
            }
            else {
              this.spinner.hideSpinner();

              this.toastrService.error(res.userMessage, '', { timeOut: 3000, closeButton: true });
            }
          },
          err => {
            this.spinner.hideSpinner();

            this.toastrService.error('server down', '', { timeOut: 3000, closeButton: true });
          }
        )
      }
    }
  }
  AddForm() {
    this.Prescription = new Prescription()
    this.prescriptionarray.push(this.Prescription);
  }


  RemoveForm(index) {

    if (this.prescriptionarray[index].idPatientPrescription > 0 || this.prescriptionarray[index].idPatientPrescription != null) {
      this.prescriptionarray[index].operationState = "d";
    }
    else {
      this.prescriptionarray[index].operationState = "d";
    }
  }
  bindformpatient(selectedpatiendata) {
    this.spinner.showSpinner();
    this.model.selectvisit = null;
    this.showvisitedetails = false;
    this.VisitArray = null;
    if (selectedpatiendata == null) {
      this.cancel();

    }
    else {
      if (Number(this.idPatientVisitp) > 0) {
      }
      else {
        this.cancel();
      }
      
      this.model1=selectedpatiendata;
      this.model1.dOB = this.dateconfig.coverttoshort(selectedpatiendata.dob);
      this.idPatient =selectedpatiendata.idPatient;
      this.model.idPatient=selectedpatiendata.idPatient;
      this.showpatientdetails = true;
      this.GetAllVisitByPatientId(selectedpatiendata.idPatient);   
      }
    

    
  }
 
  bindvisit(selectvisit) {
    this.spinner.showSpinner();
    
    if(selectvisit==null){
      this.showvisitedetails=false;
      this.prescriptionarray.splice(0);
      this.Prescription = new Prescription()
      this.prescriptionarray.push(this.Prescription);
     }
    else{
      this.rows1 = selectvisit;
      this.model1.patientVisitDescription = this.rows1.patientVisitDescription;
      this.model.idPatientVisit = selectvisit.idPatientVisit;
      this.model1.patientVisitDate = this.rows1.patientVisitDate;
      if (this.model1.patientVisitDescription != null) {
        this.showvisitedetails = true;
      }

  }
  this.spinner.hideSpinner();


  }


  cancel() {
    if (this.prescriptionarray[0].idPatientPrescription == undefined) {
      this.model1={};
     this.model={idPatientVisit : 0}
     this.prescriptionarray.splice(0);
     this.Prescription = new Prescription()
     this.prescriptionarray.push(this.Prescription);
      this.showpatientdetails = false;
      this.showvisitedetails = false;
    
    }
    else {
      this.routernav.navigate(['/dashboard/newdashboard']);
    }
  }
  GetAllVisitByPatientId(id){
    this.spinner.showSpinner();

    this.PatientvisitService.GetAllPatientVisitByByPatientID(Number(id)).subscribe(Response => {
     
      if (Response != null) {
    this.spinner.hideSpinner();

        this.VisitArray = Response;
        for (var i = 0; i < this.VisitArray.length; i++) {
          this.VisitArray[i].patientVisitDate = this.covertdate(this.VisitArray[i].patientVisitDate)

        }
    
        if(Number(this.idPatientVisitp)>0){
          for(var i=0;i<this.VisitArray.length;i++){
             if(this.VisitArray[i].idPatientVisit==Number(this.idPatientVisitp))
             {
              this.model.selectvisit=this.VisitArray[i];
              break;
             }
           }
        }
      }
      else {
    this.spinner.hideSpinner();

        this.toastrService.error('Visit Not Found', '', { timeOut: 3000, closeButton: true });
      }
    },
      err => {
    this.spinner.hideSpinner();
        
        this.toastrService.error('Visit Not Found', '', { timeOut: 3000, closeButton: true });
      });
  }
  public covertdate(date) {
    return this.dateconfig.coverttoshort(date);
  }

}
