import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  

  constructor(private http: HttpClient) { }

  LoginUser() {

  }

  Login(user: any) {
    localStorage.setItem("TokenRequired", "N");
    //  var data = "UserName=" + UserName + "&Password=" + Password;
    //  var requestHeader = new HttpHeaders({ 'Content-type': 'application/x-www-urlencoded', 'withCredentials': 'True' });
    //  this.http.request.prototype = requestHeader;
    //return this.http.post(environment.apiUrl + 'User/Login ?' + data, requestHeader);
    return this.http.post(environment.apiUrl + 'User/Login', user);
  }
  Forgot(user: any) {
    return this.http.post(environment.apiUrl + 'User/ForgotPassWord', user);
  }
  GetAlluser()
  {
    return this.http.get(environment.apiUrl + 'User/GetAlluser');
  }
}
