import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PatientInvoiceService {

  constructor(private http: HttpClient) { }
  
  savePatientInvoice(model: any) {
    return this.http.post(environment.apiUrl + 'PatientInvoice/Save', model);
  }
  GetPatientInvoiceById(id){
    return this.http.get(environment.apiUrl + 'PatientInvoice/GetPatientInvoiceById?id=' + id);

  }
  GetPatientInvoiceByIdPatient(id):Observable<any>{
    return this.http.get(environment.apiUrl + 'PatientInvoice/GetPatientInvoiceByIdPatient?id=' + id);

  }
  GetPatientInvoiceByIdPatientVisit(id) {
    return this.http.get(environment.apiUrl + 'PatientInvoice/GetPatientInvoiceByIdPatientVisit?id=' + id);
  }
  DeleteById(id) :Observable<any>{
    return this.http.delete(environment.apiUrl + 'PatientInvoice/Delete?id=' + id);
  }
  updatePatientInvoice(model: any) {
    return this.http.patch(environment.apiUrl + 'PatientInvoice/Update', model);
  }
  
  GetAllPatientInvoice():Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientInvoice/GetAllPatientInvoice');
  }
  GetRecentPatientInvoice():Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientInvoice/RecentInvoice');
  }
}
