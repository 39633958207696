import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegisterComponent } from './register';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgotpasswordComponent}from 'src/app/Forgotpassword/forgotpassword/forgotpassword.component';
import { DashboardComponent } from './content/dashboard/dashboard/dashboard.component';
import { InvoiceviewComponent } from './content/pages/invoice/invoiceview/invoiceview.component';
import { ViewPrescriptionComponent } from './content/pages/prescription/view-prescription/view-prescription.component';
const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'register', component: RegisterComponent },
      { path: 'login', component: LoginComponent },
      {path:'forgot',component:ForgotpasswordComponent},
      {path:'viewinvoice',component:InvoiceviewComponent},
      {path:'viewprescription',component:ViewPrescriptionComponent},
      
      { path: '', component: LoginComponent }
    ]
  },

  // Private layout`
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'purchase', loadChildren: () => import('../app/content/pages/purchase/purchase.module').then(m => m.PurchaseModule),
       canActivate: [AuthGuard] },
       { path: 'employee', loadChildren: () => import('../app/content/pages/employee/employee.module').then(m => m.EmployeeModule),
       canActivate: [AuthGuard] },
       { path: 'patient', loadChildren: () => import('../app/content/pages/patient/patient.module').then(m => m.PatientModule),
       canActivate: [AuthGuard] },
       { path: 'viewpatient', loadChildren: () => import('../app/content/pages/viewpatient/viewpatient.module').then(m => m.ViewpatientModule),
       canActivate: [AuthGuard] },
       { path: 'appointment', loadChildren: () => import('../app/content/pages/patient-appointment/patient-appointment.module').then(m => m.PatientAppointmentModule),
       canActivate: [AuthGuard] },
       { path: 'patientvisit', loadChildren: () => import('../app/content/pages/patientvisit/patientvisit.module').then(m => m.PatientvisitModule),
       canActivate: [AuthGuard] },
       { path: 'patienttreatment', loadChildren: () => import('../app/content/pages/patienttreatment/patienttreatment.module').then(m => m.PatienttreatmentModule),
       canActivate: [AuthGuard] },
       { path: 'invoice', loadChildren: () => import('../app/content/pages/invoice/invoice.module').then(m => m.InvoiceModule),
       canActivate: [AuthGuard] },
        { path: 'calendar', loadChildren: () => import('../app/content/pages/calender/calender.module').then(m => m.CalenderModule),
        canActivate: [AuthGuard] },
        { path: 'prescription', loadChildren: () => import('../app/content/pages/prescription/prescription.module').then(m => m.PrescriptionModule),
        canActivate: [AuthGuard] },
        { path: 'profile', loadChildren: () => import('../app/content/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard] },
        { path: 'patientreport', loadChildren: () => import('../app/content/pages/patient-detail-report/patient-detail-report.module').then(m => m.PatientDetailReportModule),
        canActivate: [AuthGuard] },
       { path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule),
       canActivate: [AuthGuard] },
       
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard/newdashboard' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
