import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../_services/auth.service';
import { AlertService } from '../../_services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/_services/login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  
  forgotform: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public authService: LoginService
  ) { }

  ngOnInit() {
    this.forgotform = this.formBuilder.group({
      EmailId: ['', Validators.required]
  });
  }
  get f() { return this.forgotform.controls; }
  tryforgot() {
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotform.invalid) {
        return;
    }
    const value = {
        EmailId: this.f.EmailId.value,
       
    };
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3500);
   
    this.authService.Forgot(value)   
        .subscribe(
            (res: any) => {
                if(res.result==1){
                               
                    let returnUrl = '/login';
                    if (this.returnUrl) {
                      returnUrl = this.returnUrl;
                    }
                    this.router.navigate([returnUrl]);
                    this.toastrService.success(res.userMessage,'',{ timeOut: 4000, closeButton: true });
               
            }
            else{
                this.toastrService.error(res.userMessage,'',{ timeOut: 4000, closeButton: true });
            }
            },
            error => {
                
               
                this.loading = false;
            }
        )
}

}
