import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  
  constructor(private http: HttpClient) { }

  savePatientPrescription(model: any) {
    return this.http.post(environment.apiUrl + 'PatientPrescription/Save', model);
  }
  updatePatientPrescription(model:any){
    return this.http.patch(environment.apiUrl + 'PatientPrescription/Update', model);

  }
  GetPatientPrescriptionByIdPatientVisit(id){
    return this.http.get(environment.apiUrl + 'PatientPrescription/GetPatientPrescriptionByIdPatientVisit?id=' +id);
  }
  getAllPrescription(): Observable<any>{
    return this.http.get(environment.apiUrl + 'PatientPrescription/GetAllPatientPrescription');

  }
  deletebyvisitid(id){
      return this.http.delete(environment.apiUrl+'PatientPrescription/DeleteByIdPatientVisit?id='+id);
  }
}
