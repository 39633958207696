import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrescriptionService } from 'src/app/_services/Prescription/prescription.service';
import { Dateconfig } from 'src/app/configuration/dateconfig/dateconfig';

@Component({
  selector: 'app-view-prescription',
  templateUrl: './view-prescription.component.html',
  styleUrls: ['./view-prescription.component.css'],
  providers:[Dateconfig]
})
export class ViewPrescriptionComponent implements OnInit {
  idPatientVisit:string;
  temp = [];
  rows: any;
  temp2 = this.rows;
  Prescriptions = [];
  public breadcrumb: any;
  model: any = {};
  
  constructor(private router:ActivatedRoute,
    private PrescriptionService:PrescriptionService,
    private dateconfig:Dateconfig,
    
    ) { }
 ;
  ngOnInit() {
    
this.idPatientVisit = this.router.snapshot.queryParamMap.get('idPatientVisitp');
this.idPatientVisit=atob(this.idPatientVisit)

    this.breadcrumb = {
      'mainlabel': 'View Prescription',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/newdashboard'
        },

      ]
    };
    this.PrescriptionService.GetPatientPrescriptionByIdPatientVisit(Number(this.idPatientVisit)).subscribe(Response => {
     
      this.rows = Response;
      this.temp2 = Response;
      this.temp = this.rows;
      this.model = this.rows;
      this.model=Response;
      
      this.model.idPatientPrescriptionDisplay=this.convertommyyy(this.rows.patientVisitDate)+"/"+this.rows.idPatientVisit;
 // this.model.patientPrescriptionsDate = this.dateconfig.coverttoshort(this.rows.patientVisitDate)
      this.Prescriptions = this.rows.patientPrescriptions;

    });
  }
  getPrint(prescription) {
    const printContents = document.getElementById(prescription).innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();
  }
  convertommyyy(date:any){
    return this.dateconfig.convertommyyy(date);
  }
  convertoshort(date){
    return this.dateconfig.coverttoshort(date);
  }

}
