import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, provideRoutes } from '@angular/router';
import { PatientInvoiceService } from 'src/app/_services/PatientInvoice/patient-invoice.service';
import { Dateconfig } from 'src/app/configuration/dateconfig/dateconfig';
import { PatientService } from 'src/app/_services/patient/patient.service';
import { Commonvariable } from 'src/app/commonvariable/commonvariable';

@Component({
  selector: 'app-invoiceview',
  templateUrl: './invoiceview.component.html',
  styleUrls: ['./invoiceview.component.css'],
  providers:[Dateconfig,Commonvariable]
})
export class InvoiceviewComponent implements OnInit {
  
  idPatientinvoice:string;
 
  rows: any;
  invoiceitemarray = [];
  public breadcrumb: any;
  model: any = {};
  patientModel:any={};
  public currency=this.commonvariable.currency;
  constructor(private router:ActivatedRoute,
    private patientInvoiceService:PatientInvoiceService,
    private dateconfig:Dateconfig,
    private patientService:PatientService,
    private commonvariable:Commonvariable) { }
 ;
  ngOnInit() {
    
this.idPatientinvoice = this.router.snapshot.queryParamMap.get('Id');
this.idPatientinvoice=atob(this.idPatientinvoice)

    this.breadcrumb = {
      'mainlabel': 'View Invoice',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/newdashboard'
        },

      ]
    };
    this.patientInvoiceService.GetPatientInvoiceById(Number(this.idPatientinvoice)).subscribe(Response => {
     
      this.rows = Response;
      this.model = this.rows;
      this.model=Response;
      this.model.idPatientInvoiceDispaly=this.convertommyyy(this.rows.patientInvoiceDate)+"-"+this.rows.idPatientInvoice;
      this.model.patientInvoiceDate = this.dateconfig.coverttoshort(this.rows.patientInvoiceDate)
      this.model.idPatient = this.rows.idPatient;
      this.invoiceitemarray = this.rows.patientInvoiceItems;
      this.bindpatient(this.rows.idPatient);  
    });
  }

  bindpatient(id){
    this.patientService.GetPatientById(Number(id)).subscribe(Response => {
      this.patientModel=Response;
    });
   
  }
  getPrint(invoice) {
    const printContents = document.getElementById(invoice).innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    location.reload();

  }
  convertommyyy(date:any){
    return this.dateconfig.convertommyyy(date);
  }
}
