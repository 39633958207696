import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { LoginService } from '../_services/login/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css'],
    
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        private spinner: NgxSpinnerService,
        
        public authService: LoginService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            EmailId: ['', Validators.required],
            Password: ['', Validators.required]
        });

        if (localStorage.getItem('currentUser')) {
            //this.authService.doLogout();
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        const value = {
            EmailId: this.f.EmailId.value,
            Password: this.f.Password.value
        };
        
        this.spinner.show();
       
          
       
        this.authService.Login(value)
        
            .subscribe(
                (res: any) => {
                    if(res.result==1){
                    if(res !=null && res.token != null && res.token != undefined )
                    {
        
                        this.setUserInStorage(res);
                        
                        let returnUrl = '/dashboard/newdashboard';
                        if (this.returnUrl) {
                          returnUrl = this.returnUrl;
                        }
                        this.spinner.hide();
                        this.router.navigate([returnUrl]);
                    this.toastrService.success(res.userMessage, '', { timeOut: 4000, closeButton: true });

                    }
                }
                else{
                    this.spinner.hide();
                    this.toastrService.error(res.userMessage, '', { timeOut: 4000, closeButton: true });
                }
                },
                error => {
                    this.spinner.hide();
                    
                    this.loading = false;
                }
            )
    }

    setUserInStorage(res) {
        
            localStorage.setItem('currentUser', JSON.stringify(res));
            localStorage.setItem('token',res.token);
            localStorage.setItem('UserTypeDescription',res.userTypeDescription);

    }
}
