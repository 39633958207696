import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PrescriptionService} from 'src/app/_services/Prescription/prescription.service';
import { Dateconfig } from 'src/app/configuration/dateconfig/dateconfig';
import { ToastrService } from 'ngx-toastr';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../confirm/confirm';
import { CustomLoaderCComponent } from 'src/app/CustomLoader/custom-loader/custom-loader-c/custom-loader-c.component';
import { precision } from 'chartist';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.css'],
  providers:[Dateconfig,CustomLoaderCComponent]
})
export class PrescriptionsComponent implements OnInit {
  temp = [];
  rows: any;
  temp2 = this.rows;
  confirmResult=null;
  public breadcrumb:any;
  @BlockUI('baseStyle') blockUIBaseStyle: NgBlockUI;
  @BlockUI('noStylingClasses') blockUINoStylingClasses: NgBlockUI;

  public config: PerfectScrollbarConfigInterface = { suppressScrollY : true };

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;


  constructor(
    private spinner: CustomLoaderCComponent,
    private PrescriptionService:PrescriptionService,
    private dateconfig:Dateconfig,
    private toastrService:ToastrService,
    private SimpleModalService:SimpleModalService) { }

  ngOnInit() {
    this.spinner.showSpinner();
    
    this.breadcrumb = {
      'mainlabel': 'All Prescriptions',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/newdashboard'
        },

      ]
    };
    
    this.PrescriptionService.getAllPrescription().subscribe(Response => {
      this.rows = Response;
       this.temp2 = Response;
       this.temp = Response;
       this.spinner.hideSpinner();
     
      
      });

  }
  public convertdate(date1){

    return this.dateconfig.coverttoshort(date1);
   }
   
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.rows = [...this.temp2];
    this.temp = [...this.rows];
    const temp = this.rows.filter(function (d) {
      return  d.patientFullName.toLowerCase().indexOf(val) !== -1 ||
      d.patientVisitDescription.toString().toLowerCase().indexOf(val) !== -1 ||
      !val;
   });
    this.rows = temp;
  
  }
   
  
   
  deletePrescription(id) {
    this.SimpleModalService.addModal(ConfirmComponent, {
      title: 'Confirmation',
      message: 'Are you sure to delete this record ?'})
      .subscribe((isConfirmed) => {
        // Get modal result
        this.confirmResult = isConfirmed;
       
        if(isConfirmed){
         this.spinner.showSpinner();
          
          this.PrescriptionService.deletebyvisitid(id).subscribe(
            (res: any) => {
              
              if(res.result==1){
             this.spinner.hideSpinner();

                this.toastrService.success(res.userMessage ,'', { timeOut: 1000, closeButton: true });
              this.ngOnInit();  
              }
              else{
                  this.spinner.hideSpinner();
                  this.toastrService.error(res.userMessage ,'', { timeOut: 3000, closeButton: true });
                 }
              },
            err => {
              this.spinner.hideSpinner();
              this.toastrService.error('server is down','', { timeOut: 3000, closeButton: true });
            }
          )
          this.spinner.hideSpinner();
        }
    });
  }
  encrypt(id){
    return btoa(id);
  }
 
}
