import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BlockTemplateComponent } from 'src/app/_layout/blockui/block-template.component';
import { BreadcrumbModule } from 'src/app/_layout/breadcrumb/breadcrumb.module';
import { CardModule } from '../../partials/general/card/card.module';
import{NgxSpinnerModule} from 'ngx-spinner';
import { NewinvoiceComponent } from './newinvoice/newinvoice.component';
import { AllinvoicesComponent } from './allinvoice/allinvoice.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomLoaderModule } from 'src/app/CustomLoader/custom-loader/custom-loader.module';
import { InvoiceviewComponent } from './invoiceview/invoiceview.component';


@NgModule({
  declarations: [NewinvoiceComponent, AllinvoicesComponent, InvoiceviewComponent],
  imports: [
    CustomLoaderModule,
    CommonModule,
    ChartistModule,
    FormsModule,
    NgxDatatableModule,
    PerfectScrollbarModule,
    NgSelectModule,
    NgbModule,
    NgxSpinnerModule,
    BreadcrumbModule,
    CardModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    RouterModule.forChild([
      {
        path: 'addinvoice',
        component: NewinvoiceComponent
      },
      {
        path: 'viewinvoice',
        component: InvoiceviewComponent
      },
      {
        path: 'invoices',
        component: AllinvoicesComponent
      }
    ])
  ]
})

export class InvoiceModule { }
