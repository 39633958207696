import { Component, OnInit } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-custom-loader-c',
  templateUrl: './custom-loader-c.component.html',
  styleUrls: ['./custom-loader-c.component.css'],
  
  
})
export class CustomLoaderCComponent implements OnInit {

   
    constructor( private spinner: NgxSpinnerService, ) { }

  ngOnInit() {
  }
 public showSpinner(){
   this.spinner.show();
 }
 public hideSpinner(){
  this.spinner.hide();

 }
}
