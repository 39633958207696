import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//import {LoginService} from './login.service';

/**
 * This interceptor automatically adds the token header needed by our backend API if such token is present
 * in the current state of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    const token = localStorage.getItem('token');
    let newHeaders = req.headers;
    if (localStorage.getItem("TokenRequired") != "N") {
      newHeaders = newHeaders.set('Authorization', 'Bearer ' + token);
    }
    else
    {
        localStorage.setItem("TokenRequired", "");
    }
    const authReq = req.clone({headers: newHeaders});
    return next.handle(authReq);
  }
}