import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPrescriptionComponent } from './add-prescription/add-prescription.component';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BlockTemplateComponent } from 'src/app/_layout/blockui/block-template.component';
import { BreadcrumbModule } from 'src/app/_layout/breadcrumb/breadcrumb.module';
import { CardModule } from '../../partials/general/card/card.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomLoaderModule } from 'src/app/CustomLoader/custom-loader/custom-loader.module';
import { ViewPrescriptionComponent } from './view-prescription/view-prescription.component';


@NgModule({
  declarations: [AddPrescriptionComponent, PrescriptionsComponent, ViewPrescriptionComponent],
  imports: [ 
    CustomLoaderModule,
    CommonModule,
    ChartistModule,
    FormsModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    PerfectScrollbarModule,
    NgbModule,
    BreadcrumbModule,
    CardModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    RouterModule.forChild([
      {
        path: 'addPrescription',
        component: AddPrescriptionComponent
      },
      {
        path: 'prescriptions',
        component: PrescriptionsComponent
      },
      
      {
        path: 'viewprescription',
        component: ViewPrescriptionComponent
      },
    ])
    
  ]
})


export class PrescriptionModule { }
