import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PatientvisitService } from 'src/app/_services/Patientvisit/patientvisit.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/_services/patient/patient.service';
import { PatientInvoiceItem } from 'src/app/Entites/patient-invoice-item/patient-invoice-item.module';
import { PatientInvoiceService } from 'src/app/_services/PatientInvoice/patient-invoice.service';
import { Dateconfig } from 'src/app/configuration/dateconfig/dateconfig';
import { ToastrService } from 'ngx-toastr';
import { CustomLoaderCComponent } from 'src/app/CustomLoader/custom-loader/custom-loader-c/custom-loader-c.component';

@Component({
  selector: 'app-newinvoice',
  templateUrl: './newinvoice.component.html',
  styleUrls: ['./newinvoice.component.css'],
  providers: [Dateconfig,CustomLoaderCComponent],
  
})
export class NewinvoiceComponent implements OnInit {
  @ViewChild('ng-select') ngSelect;
  @BlockUI('singleSelect') blockUISingleSelect: NgBlockUI;
  @BlockUI('multipleSelect') blockUIMultipleSelect: NgBlockUI;
  @BlockUI() blockUI: NgBlockUI;
  temp = [];
  rows: any;
  NewPatientInvoiceItem = new PatientInvoiceItem();
  public singleSelectArray: any;
  public VisitArray = [];
  invoiceitemarray = [];
  public breadcrumb: any;
  model: any = {};
  options = {
    close: false,
    expand: true,
    minimize: true,
    reload: false
  };

  model1: any = {};
  rows1: any;
  temp1 = [];
  idPatientinvoice:string;
  loading = false;
  submitted = false;
  IdPatientVisit: number;
  idPatient: number;
  NewidPatient:string;
  f: NgForm;
  public showpatientdetails: boolean = false;
  public showvisitedetails: boolean = false;
  public patientboolean = false;
  public visitsboolean = false
  constructor(
    private patientService: PatientService,
    private patientInvoiceService: PatientInvoiceService,
    private router: ActivatedRoute,
    private spinner: CustomLoaderCComponent,
    private routernav: Router,
    private PatientvisitService: PatientvisitService,
    private dateconfig: Dateconfig,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    
    this.model1.totalAmount = 0
    this.spinner.showSpinner();
    this.patientService.GetAllpatient().subscribe(Response => {
      this.rows = Response;
      this.temp = Response;
      this.singleSelectArray = Response;
      this.spinner.hideSpinner();

    });
    
    this.NewidPatient = this.router.snapshot.queryParamMap.get('NewidPatient');
this.NewidPatient=atob(this.NewidPatient)
this.idPatientinvoice = this.router.snapshot.queryParamMap.get('Id');
this.idPatientinvoice=atob(this.idPatientinvoice)

 if (Number(this.idPatientinvoice) > 0) {
      this.spinner.showSpinner();
      this.visitsboolean = true;
      this.patientboolean = true;
      this.idPatientinvoice = this.idPatientinvoice;
      this.breadcrumb = {
        'mainlabel': 'Update Invoice',
        'links': [
          {
            'name': 'Home',
            'isLink': true,
            'link': '/dashboard/newdashboard'
          },
        ]
      };

      this.patientInvoiceService.GetPatientInvoiceById(Number(this.idPatientinvoice)).subscribe(Response => {
        this.rows = Response;
        this.temp = this.rows;
        this.model = this.rows;
        this.model.patientInvoiceDate = this.dateconfig.getshortdate(this.rows.patientInvoiceDate)
        this.model1.idPatient = this.rows.idPatient;
        this.invoiceitemarray = this.rows.patientInvoiceItems;
        if (this.rows.patientInvoiceItems != null) {
          this.calculatetotal();
        }
        this.bindformpatient(this.rows);
        this.bindvisit(this.rows);
        this.spinner.hideSpinner();
      });
    }
    else {
      this.breadcrumb = {
        'mainlabel': 'Add Invoice',
        'links': [
          {
            'name': 'Home',
            'isLink': true,
            'link': '/dashboard/newdashboard'
          },

        ]
      };
      this.model.patientInvoiceDate=this.dateconfig.getcurrentdate();
      this.model.patientInvoiceAmountPaid = 0;
      this.NewPatientInvoiceItem = new PatientInvoiceItem();
      this.invoiceitemarray.push(this.NewPatientInvoiceItem);
      if(Number(this.NewidPatient)>0){
        this.model.idPatient=Number(this.NewidPatient)
        this.bindformpatient(this.model);
      }
    }
  }

  AddForm() {

    this.NewPatientInvoiceItem = new PatientInvoiceItem();
    this.invoiceitemarray.push(this.NewPatientInvoiceItem);
  }

  RemoveForm(index) {
    if (this.invoiceitemarray[index].idPatientInvoiceItem > 0 || this.invoiceitemarray[index].idPatientInvoiceItem != null) {
      this.invoiceitemarray[index].operationState = "d";
      this.invoiceitemarray[index].patientInvoiceItemAmount = 0;
      this.calculatetotal();
    }
    else {
      this.invoiceitemarray[index].operationState = "d";
      this.invoiceitemarray[index].patientInvoiceItemAmount = 0;
      this.calculatetotal();
    }

  }
  bindformpatient(selectedpatiendata) {
    this.showvisitedetails = false;
    this.VisitArray = null;
    this.model.selectvisit = null;
    if (selectedpatiendata == null) {
      this.cancel();
      this.VisitArray = null;
    }
    else {
      this.spinner.showSpinner();
      this.patientService.GetPatientById(Number(selectedpatiendata.idPatient)).subscribe(Response => {
      
        this.rows1 = Response;
        this.model.selectpatient = Response;
        this.model1.patientContactNo = this.rows1.patientContactNo;
        this.model1.patientFirstName = this.rows1.patientFirstName;
        this.model1.patientLastName = this.rows1.patientLastName;
        this.model1.patientEmailId = this.rows1.patientEmailId;
        this.model1.patientGender = this.rows1.patientGender;
        this.model1.dOB = this.dateconfig.coverttoshort(this.rows1.dob);
        this.idPatient = this.rows1.idPatient;
        this.showpatientdetails = true;
      });
     
      this.PatientvisitService.GetAllPatientVisitByByPatientID(Number(selectedpatiendata.idPatient)).subscribe(Response => {
             
        this.VisitArray = Response;
        if (Response != null) {

          for (var i = 0; i < this.VisitArray.length; i++) {
            this.VisitArray[i].patientVisitDate = this.covertdate(this.VisitArray[i].patientVisitDate)
          }
        }
        this.spinner.hideSpinner();   
      });
     
    }
   

  }
  public covertdate(date) {
    return this.dateconfig.coverttoshort(date);
  }
  bindvisit(selectvisit) {
    if (selectvisit == null) {
      this.showvisitedetails = false;
      this.invoiceitemarray.splice(0);
      this.NewPatientInvoiceItem = new PatientInvoiceItem()
    this.invoiceitemarray.push(this.NewPatientInvoiceItem);
    }
    else {
      this.spinner.showSpinner();
        
        this.PatientvisitService.GetVisitById(Number(selectvisit.idPatientVisit)).subscribe(Response => {
        this.rows1 = Response;
        this.model1.patientVisitDescription = this.rows1.patientVisitDescription;
        this.model.patientVisitDescription = this.rows1.patientVisitDescription;
        this.model.idPatientVisit = this.rows1.idPatientVisit;
        this.model1.patientVisitDate = this.dateconfig.coverttoshort(this.rows1.patientVisitDate);
        this.model.patientVisitDate = this.rows1.patientVisitDate;
        this.showvisitedetails = true;
        this.rows1.patientVisitDate = this.covertdate(Response.patientVisitDate);
        this.model.selectvisit = this.rows1;
        this.spinner.hideSpinner();

      });
    }
  
 
  }
  cancel() {
    if (this.invoiceitemarray[0].idPatientInvoiceItem == undefined) {
      this.model1={};
      this.model={idPatientVisit: 0,patientInvoiceDate:this.dateconfig.getcurrentdate() };
      this.invoiceitemarray.splice(0);
      this.NewPatientInvoiceItem = new PatientInvoiceItem()
    this.invoiceitemarray.push(this.NewPatientInvoiceItem);
      this.showvisitedetails = false;
      this.showpatientdetails = false;
      this.routernav.navigate(['/invoice/addinvoice']);
    }
    else {
      this.routernav.navigate(['/invoice/invoices']);
    }
  }
  
  //save or update invoice inforamtion in database
  Patientinvoice(isValid: any) {
    

    if (isValid && this.model.patientInvoiceAmountPaid<=this.model1.totalAmount && 0<=this.model.patientInvoiceAmountPaid) {
      this.spinner.showSpinner();
      if (this.invoiceitemarray[0].idPatientInvoiceItem == undefined) {
        for (var i = 0; i < this.invoiceitemarray.length; i++) {
          if (this.invoiceitemarray[i].operationState == "d") {
            this.invoiceitemarray.splice(i, 1);
          }

        }
        this.model.patientInvoiceItems = this.invoiceitemarray;
        this.model.idPatient = this.idPatient;
        this.model.idUser=localStorage.getItem('Id');
        this.model.patientInvoiceCreatedBy=localStorage.getItem('Id');
        this.patientInvoiceService.savePatientInvoice(this.model).subscribe(
          (res: any) => {
            if (res.result == 1) {
              this.spinner.hideSpinner();
              window.open('/viewinvoice?Id='+btoa(res.idPatientInvoice)+'','_blank');
              this.toastrService.success(res.userMessage, '', { timeOut: 2000, closeButton: true });
              this.routernav.navigate(['/invoice/invoices']);
      
            }
            else {
              this.spinner.hideSpinner();
              this.toastrService.error(res.userMessage, '', { timeOut: 3000, closeButton: true });
            }
          },
          err => {
            this.spinner.hideSpinner();
            this.toastrService.error('server down', '', { timeOut: 3000, closeButton: true });
          }
        )
       
      }
      else {
        for (var i = 0; i < this.invoiceitemarray.length; i++) {
          if (this.invoiceitemarray[i].operationState == "d" && this.invoiceitemarray[i].idPatientInvoiceItem == undefined) {
            this.invoiceitemarray.splice(i, 1);
          }

        }
        this.model.patientInvoiceItems = this.invoiceitemarray;
        this.model.idUser=localStorage.getItem('Id');
        this.model.patientInvoiceModifiedBy=localStorage.getItem('Id');
        this.model.idPatient = this.idPatient;
        this.patientInvoiceService.updatePatientInvoice(this.model).subscribe(
          (res: any) => {
            if (res.result == 1) {
              this.spinner.hideSpinner();
              this.toastrService.success(res.userMessage, '', { timeOut: 2000, closeButton: true });
              window.open('/viewinvoice?Id='+btoa(res.idPatientInvoice)+'','_blank');

              this.routernav.navigate(['/invoice/invoices']);
            }
            else {
              this.spinner.hideSpinner();
              this.toastrService.error(res.userMessage, '', { timeOut: 3000, closeButton: true });
            }
          },
          err => {
            this.spinner.hideSpinner();
            this.toastrService.error('server down', '', { timeOut: 3000, closeButton: true });
          }
        )
        
      }
    }

  }

  //calculate total amount of invoice

  public calculatetotal() {
  
    this.model1.totalAmount = 0;
    for (var i = 0; i < this.invoiceitemarray.length; i++) {
      if (this.invoiceitemarray[i].patientInvoiceItemAmount) {
        this.model1.totalAmount = this.model1.totalAmount + this.invoiceitemarray[i].patientInvoiceItemAmount;
      }
    }
    this.remainingamount();
  }

  // calculate remining amount  after paid.
  remainingamount() {
    this.model1.remainingamount = this.model1.totalAmount - this.model.patientInvoiceAmountPaid
  }

}
