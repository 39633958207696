import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoaderCComponent } from './custom-loader-c/custom-loader-c.component';
import { BlockTemplateComponent } from 'src/app/_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { CardModule } from 'src/app/content/partials/general/card/card.module';
import { BreadcrumbModule } from 'src/app/_layout/breadcrumb/breadcrumb.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChartistModule } from 'ng-chartist';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CustomLoaderCComponent],
  imports: [
    CommonModule,
    CommonModule,
    ChartistModule,
    NgxSpinnerModule,
    NgSelectModule,
    FormsModule,
    NgxDatatableModule,
    PerfectScrollbarModule,
    NgbModule,
    BreadcrumbModule,
    CardModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [CustomLoaderCComponent]
})
export class CustomLoaderModule { }
