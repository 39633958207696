import { DatePipe } from '@angular/common'
import { Component } from '@angular/core';
@Component({
    providers:[DatePipe]
  })
export class Dateconfig {

    //public datepipe: DatePipe;
  //  const datepipe: DatePipe = new DatePipe();
    protected datePipe: DatePipe = new DatePipe('en-US')
    public readdate(date:any){
      try{
        date = this.datePipe.transform(date, "dd-MM-yyyy hh:mm:a");
        return date;
       }
       catch(exp){
         let myVar = JSON.parse(date);
         let myDate = new Date(myVar.EEEE, myVar.MMMM, myVar.d, myVar.y, myVar.h,myVar.mm,myVar.a)
         date = this.datePipe.transform(myDate, "EEEE, MMMM d, y, h:mm: a");
         return date;
       }
    }
    public readdateonly(date:any){
      return this.datePipe.transform(new Date(date),"eeee, mmmm d, y");
  }
    public getcurrentdatetimeinput(date:any){
  
   
      return this.datePipe.transform(new Date(date),"yyyy-MM-ddThh:mm");
    }
    public getcurrentdatetime(){
     
      let dateString = new Date();
      return this.datePipe.transform(new Date(dateString),"yyyy-MM-ddThh:mm");
    }
    public getcurrentdate(){

      let dateString = new Date();
      return this.datePipe.transform(new Date(dateString),"yyyy-MM-dd");
    }
    coverttoshort(date:any){
      try{
        date = this.datePipe.transform(date, "dd-MM-yyyy");
        return date;
       }
       catch(exp){
         let myVar = JSON.parse(date);
         let myDate = new Date(myVar.EEEE, myVar.MM, myVar.dd, myVar.yyyy, )
         date = this.datePipe.transform(myDate, "EEEE, MM dd, yyyy");
         return date;
       }
    

     return this.datePipe.transform(new Date(date),"dd-MM-yyyy");
    }
    public coverttoforcalender(date:any){
      
      return (new Date(date));
     }
     public getshortdate(date:any){
      return this.datePipe.transform(new Date(date),"yyyy-MM-dd");
     
    }
    public convertommyyy(date:any){
      return this.datePipe.transform(new Date(date),"MMyyyy");
     
    }
}
