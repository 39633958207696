import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientvisitService {

  constructor(private http: HttpClient) { }

  GetAllLastPatientVisit(): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientVisit/GetAllLastVisit');
  }
  updatevisit(model: any) {
    return this.http.patch(environment.apiUrl + 'PatientVisit/Update', model);

  }
  GetVisitById(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientVisit/GePatientVisitByID?id=' + id);
  }

  GetAllPatientVisitByByPatientID_Or_Visitdate(model): Observable<any> {
    return this.http.post(environment.apiUrl + 'PatientVisit/GetAllPatientVisitByByPatientID_Or_Visitdate' ,model);
  }
  GetAllPatientVisitByByPatientID(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientVisit/GetAllPatientVisitByByPatientID?id=' + id);
  }

  savevisit(model: any) {
    return this.http.post(environment.apiUrl + 'PatientVisit/Save', model);
  }
  Allvisit(): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientVisit/GetAllPatientVisit');
  }
  delete(id) {
    
    return this.http.delete(environment.apiUrl + 'PatientVisit/Delete?id=' + id);
  }
  getLastPatientVisitByPatientID(id) {
    return this.http.get(environment.apiUrl + 'PatientVisit/GetLastPatientVisitByPatientID?id=' + id);
  }
}
